import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Linkit = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Linkit" />
    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Linkit</h1>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="spacer-60" />
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://public.tableau.com/app/profile/kirkkohallitus.tilastot/viz/Hautaustilasto/Story1"
            >
              Hautaustilastot - Kirkkohallitus{" "}
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hautajaiset.fi/"
            >
              Suomen Hautaustoimistojen Liitto Ry{" "}
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://blog.absentus.com/fi/muistomerkki-voi-olla-laheisesta-kertova-taideteos"
            >
              Absentus - Muistomerkki voi olla läheisestä kertova taideteos{" "}
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://uutiskirje.sht-tukku.fi/a/s/140885812-32163aea67ca4e662dca332dd4c76c84/3171173"
            >
              SHT Tukku Oy - Hyvät hautajaiset{" "}
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://absentus.com/"
            >
              Absentus Oy
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://shk.fi/doc/absentus-uudet-materiaalit-tiimalasi-1-2019.pdf"
            >
              Absentus - Tiimalasi - Uusien materiaalien käyttö
              hautamuistomerkeissä
            </a>
          </p>
        </div>
        <div className="spacer-35" />
      </div>
      <div className="spacer-35" />
    </div>
  </Layout>
)

export default Linkit
